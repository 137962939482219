@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.graph {
  border: 1px solid #eee;
  min-width: 300px;
  min-height: 400px;
}

.action_text {
  font-size: 0.8em;
  color: #555;
}

.martb-15 {
  margin: 15px 0px;
}

.mart-20 {
  margin-top: 20px;
}

.padtb-15 {
  padding: 15px 0px;
}

.padt-20 {
  padding-top: 20px;
}

.center-50 {
  margin: auto;
  width: 50%;
}

.center-60 {
  margin: auto;
  width: 60%;
}

.center-70 {
  margin: auto;
  width: 70%;
}

a.isDisabled {
  color: currentColor;
  display: inline-block;
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
  text-decoration: none;
}

div.list-view-pf-additional-info {
  width: auto;
  flex: 0 0 auto;
}

table.csv-html-table {
  border: 1px solid #ccc;
}

.react-json-view div.variable-row {
  word-break: break-all;
}

.filepond--file-info .filepond--file-info-sub {
  font-size: 0.725em;
  opacity: 0.8;
}

.filepond--file-info .filepond--file-info-main {
  font-size: 0.85em;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.mt-4 {
  margin-top: 4em;
}

.mt-5 {
  margin-top: 5em;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mb-3 {
  margin-bottom: 3em;
}

.mb-4 {
  margin-bottom: 4em;
}

.mb-5 {
  margin-bottom: 5em;
}

.filepond--root {
  font-size: 1.2rem;
}

ol.breadcrumbs-pf-title,
ol.breadcrumbs-pf-title li:active {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

span.fa-0x5x {
  font-size: 11px !important;
  vertical-align: 0px !important;
  padding: 0 9px 0 3px;
}

.list-view-pf .list-group-item-text {
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-all;
}

div.list-view-pf .list-group-item-heading {
  white-space: pre-wrap;
  text-overflow: inherit;
  word-break: break-all;
}

.btn-pad-0 button,
.btn-pad-0 a {
  padding-left: 0px;
}

.progress-bar-danger {
  background-color: #3f9c35 !important;
}

.progress-bar-warning {
  background-color: #39a5dc !important;
}

.progress-bar-success {
  background-color: #c00 !important;
}

.modal-75 .modal-dialog {
  width: 75% !important;
}

.jsonOdd {
  background: #eee;
}

.btn-full-width div.dropdown.btn-group,
.btn-full-width div.dropdown.btn-group button.dropdown-toggle,
.btn-full-width div.dropdown.btn-group ul.dropdown-menu {
  width: 100%;
}

.btn-full-width div.dropdown.btn-group {
  display: contents;
}

.btn-full-width div.dropdown.btn-group button.dropdown-toggle {
  text-align: left;
}

.btn-full-width div.dropdown.btn-group button.dropdown-toggle span.caret {
  float: right;
  margin-top: 5px;
}

.jobiteration-carousel.carousel a.carousel-control {
  color: #0487cd;
  float: right;
  background-image: none;
  position: relative;
  top: -20px;
}

.jobiteration-carousel.carousel a.carousel-control.left {
  left: -40px;
}

.jobiteration-carousel.carousel a.carousel-control span.glyphicon {
  top: 30%;
}

.btn-link:hover,
.btn-link:focus {
  outline: none;
}

.line-ht-72 {
  line-height: 72px;
}

div.report-table div div,
div.csv-report-table {
  position: relative;
  overflow: auto !important;
  height: calc(100vh - 373px);
}

.app-card-tile.catalog-tile-pf {
  width: auto;
  height: 230px;
  margin: 0 0px 40px 0;
  border-radius: 7px;
  border: 0px solid #e0e0e0d1;
  padding: 0px;
  box-shadow: -2px 2px 7px 1px #a2a2a22d;
  -webkit-transition: box-shadow 0.2s ease-out;
  -moz-transition: box-shadow 0.2s ease-out;
  -o-transition: box-shadow 0.2s ease-out;
  -ms-transition: box-shadow 0.2s ease-out;
  transition: box-shadow 0.2s ease-out;
}

.app-card-tile.catalog-tile-pf .catalog-tile-pf-footer-o {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  background-color: #e2e2e236;
  border-radius: 0px 0px 7px 7px;
  background: #bdc3c7;
  background: linear-gradient(to right, #d4d1d1, #f3f4f5);
  color: #777;
}

.app-card-tile.catalog-tile-pf:hover,
.app-card-tile.catalog-tile-pf:focus {
  box-shadow: -2px 2px 7px 3px #5d58b14a;
  /* border: 1px solid #5898b13b; */
}

.app-card-tile.catalog-tile-pf:hover .catalog-tile-pf-title,
.app-card-tile.catalog-tile-pf:focus .catalog-tile-pf-title {
  background: linear-gradient(to right, #d346fc, #3f5efb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.toolbar-pf-results.myApps-results-disp {
  border-top: none;
}

.app-card-tile .catalog-tile-pf-title,
.app-card-tile .catalog-tile-pf-description {
  color: #797979;
}

.app-card-tile.app-card-realtime-tile {
  background: #eaf7ff;
}

.app-card-tile.app-card-realtime-tile .catalog-tile-pf-body .catalog-tile-pf-description::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #e9f7ff 75%);
}

.app-card-tile .catalog-tile-pf-header {
  height: 1.2em;
}

.template-recc-card-tile .catalog-tile-pf-header {
  display: flex;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 400;
  background: url('https://storage.googleapis.com/dt-static/images/pipeline-default.jpg');
  background-size: 100% 100%;
}

.myApps-results-disp .toolbar-pf-results {
  border-top: 0px;
}

.myApps-results-disp h5 {
  font-weight: bold;
  color: #555;
}

.app-card-tile .catalog-tile-pf-description {
}

.app-card-tile .catalog-tile-pf-title {
  font-size: 1.6em;
  font-weight: 600;
  line-height: 1.2em;
  word-wrap: break-word;
  word-break: break-word;
  text-transform: capitalize;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  min-height: 3.7em;
  height: 3.7em;
  overflow: hidden;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
  -webkit-transition: background 0.2s ease-out;
  -moz-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  -ms-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.app-card-tile .catalog-tile-pf-body .catalog-tile-pf-subtitle {
  font-size: 12px;
}

.app-card-tile .catalog-tile-pf-body {
  margin-top: 0px;
  padding: 25px 25px 10px 25px;
}

.app-card-tile .catalog-tile-pf-badge-container,
.app-store-button-container .catalog-tile-pf-badge-container {
  display: flex;
  flex: 1 1;
  justify-content: flex-start;
  overflow: hidden;
  margin-right: 5px;
  margin-bottom: 3px;
}

.catalog-tile-pf-badge-container .catalog-tile-pf-badge {
  font-size: 16px;
  padding-right: 5px;
  display: flex;
  /* flex: 1 1; */
  justify-content: flex-end;
  min-width: 21px;
  padding-left: 0px;
}

.app-card-tile .catalog-tile-pf-sub-tags,
.app-store-button-container .catalog-tile-pf-sub-tags {
  height: 100%;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}

.app-store-card-m .app-store-button-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 1.2em;
}

.app-card-tile .catalog-tile-pf-body .catalog-tile-pf-title-img object,
.app-card-tile .catalog-tile-pf-body .catalog-tile-pf-title-img img {
  width: auto;
  height: 40px;
}

.app-card-tile .catalog-tile-pf-body .catalog-tile-pf-title-tags,
.app-store-button-container .catalog-tile-pf-title-tags {
  color: #fff;
  padding: 0 4px;
  font-size: 0.9em;
  border-radius: 5px;
  margin: 5px 3px 0 0;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}

.role-personalise-wizard section.wizard-pf-row {
  max-height: calc(100vh - 360px);
}

.role-personalise-wizard .wizard-pf-steps .wizard-pf-steps-indicator .wizard-pf-step .wizard-pf-step-number {
  visibility: hidden;
  /* position: relative; */
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.role-personalise-wizard
  .wizard-pf-steps
  .wizard-pf-steps-indicator
  .wizard-pf-step:first-child
  .wizard-pf-step-number:after {
  content: '\F123';
  visibility: visible;
  /* position: absolute; */
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 50%;
  border: solid 2px #39a5dc;
  color: #bbb;
  font-size: 25px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  width: 40px;
  display: inline-block;
  left: calc(50% - 20px);
  position: absolute;
  top: -5px;
}

.role-personalise-wizard
  .wizard-pf-steps
  .wizard-pf-steps-indicator
  .wizard-pf-step:nth-child(2)
  .wizard-pf-step-number:after {
  content: '\F275';
  visibility: visible;
  /* position: absolute; */
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 50%;
  border: solid 2px #39a5dc;
  color: #bbb;
  font-size: 20px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  width: 40px;
  display: inline-block;
  left: calc(50% - 20px);
  position: absolute;
  top: -5px;
}

.role-personalise-wizard
  .wizard-pf-steps
  .wizard-pf-steps-indicator
  .wizard-pf-step:nth-child(3)
  .wizard-pf-step-number:after {
  content: '\F007';
  visibility: visible;
  /* position: absolute; */
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 50%;
  border: solid 2px #39a5dc;
  color: #bbb;
  font-size: 25px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  width: 40px;
  display: inline-block;
  left: calc(50% - 20px);
  position: absolute;
  top: -5px;
}

.role-personalise-wizard
  .wizard-pf-steps
  .wizard-pf-steps-indicator
  .wizard-pf-step.active
  .wizard-pf-step-number:after {
  background-color: #39a5dc;
  border-color: #39a5dc;
  cursor: default;
  color: #fff;
}

.role-personalise-wizard
  .wizard-pf-steps
  .wizard-pf-steps-indicator
  .wizard-pf-step
  .wizard-pf-step-number:hover:after {
  background-color: #39a5dc;
  color: #fff;
}

.role-personalise-wizard
  .wizard-pf-steps-indicator
  .wizard-pf-step.active
  ~ .wizard-pf-step
  .wizard-pf-step-number:after {
  border-color: #bbb;
  background: #fff;
}

.role-personalise-wizard .wizard-pf-steps {
  border-bottom: none;
  margin-bottom: 25px;
}

.role-personalise-wizard .wizard-pf-steps-indicator .wizard-pf-step a .wizard-pf-step-title {
  margin-top: 70px;
}

.role-personalise-wizard .wizard-pf-steps-indicator {
  padding: 5px 0 0;
  background: #f5f5f5;
}

.role-personalise-wizard .modal-header {
  background-color: #fff;
}

.role-personalise-wizard {
  width: 550px;
}

.role-personalise-wizard form .form-group label {
}

.role-personalise-wizard
  .wizard-pf-steps-indicator
  .wizard-pf-step.active
  ~ .wizard-pf-step
  .wizard-pf-step-number:hover:after {
  color: #808080;
  border-color: #808080;
}

.role-personalise-wizard .wizard-pf-footer {
  border-top: none;
  display: block;
}

.role-personalise-wizard .modal-title {
  font-size: 15px;
}

.role-personalise-wizard .radioMLLevel div,
.role-personalise-wizard .radioInds div,
.role-personalise-wizard .radioRole div {
  position: relative;
  margin-bottom: 5px;
}

.role-personalise-wizard .radioMLLevel div input[type='radio'],
.role-personalise-wizard .radioInds div input[type='radio'],
.role-personalise-wizard .radioRole div input[type='radio'] {
  position: absolute;
}

.role-personalise-wizard .wizard-pf-main {
  padding: 1em 5em;
  color: #555;
}

.role-personalise-wizard form .control-label {
  margin-bottom: 15px;
  font-size: 1.3em;
}

.role-personalise-wizard .radioMLLevel div span,
.role-personalise-wizard .radioInds div span,
.role-personalise-wizard .radioRole div span {
  font-size: 1.2em;
  padding-left: 15px;
  font-weight: 100;
}

.video-container {
  position: relative;
}

.video-container .play-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: #00000099;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container .play-icon i {
  font-size: 3em;
  cursor: pointer;
}

.play-icon i:hover {
  color: #007bff !important;
}

.text-white {
  color: #fff;
}

.center-align-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.fit-current-screen-width-card {
  min-height: calc(100vh - 220px);
}

div.template-recc-card-tile {
  padding: 0px;
}

div.template-recc-card-tile .catalog-tile-pf-badge-container {
  position: absolute;
}

div.template-recc-card-tile .catalog-tile-pf-icon {
  font-size: 40px;
  /* height: auto; */
  width: 100%;
  max-height: 130px;
  max-width: 100%;
  min-width: 40px;
  height: 130px;
}

div.template-recc-card-tile .catalog-tile-pf-subtitle {
  height: 60px;
  overflow: hidden;
  font-size: 12px;
}

div.template-recc-card-tile .catalog-tile-pf-body {
  padding: 0 15px 0;
  margin-top: 5px;
}

div.template-recc-card-tile .catalog-tile-pf-body .catalog-tile-pf-description {
  margin-top: 5px;
}

div.template-recc-card-tile.catalog-tile-pf {
  height: 100%;
  width: auto;
}

div.template-recc-card-tile .catalog-tile-pf-body .catalog-tile-pf-description::after {
  background: none;
  display: none;
}

div.template-recc-card-tile .catalog-tile-pf-title {
  height: 50px;
  overflow: hidden;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.mbtn {
  text-decoration: none;
  color: #fff;
  background-color: #26a69a;
  text-align: center;
  letter-spacing: 0.5px;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  cursor: pointer;
  font-size: 1.3rem;
  outline: 0;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.mbtn:hover {
  background-color: #2bbbad;
  color: #fff;
  -webkit-box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.mbtn:focus {
  background-color: #1d7d74;
  outline: 0;
  color: #fff;
}

.mbtn-lt {
  text-decoration: none;
  color: #666;
  background-color: #ffffff;
  text-align: center;
  letter-spacing: 0.5px;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  cursor: pointer;
  font-size: 1.3rem;
  outline: 0;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.mbtn-lt:hover {
  background-color: #fdfdfd;
  color: #555;
  -webkit-box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.mbtn-lt:focus {
  background-color: #fdfdfd;
  outline: 0;
  color: #555;
}

.modal-backdrop.in {
  opacity: 0.8;
  filter: grayscale(100%);
}

.role-personalise-wizard .modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 8px;
}

.appV-actions-panel {
  margin-top: 20px;
  margin-bottom: 20px;
}

.appV-app-runs {
  margin-top: 0px;
  margin-bottom: 50px;
}

.appV-actions-panel .appV-actions-panel-data {
  height: 60px;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.appV-actions-panel-rt .list-group-item:hover,
.appV-actions-panel-rt .list-group-item:focus,
.appV-actions-panel-rt .list-group-item:active {
  background-color: #ffffff;
}

.appV-actions-panel-rt .list-view-pf-main-info {
  padding-bottom: 0px;
  padding-top: 10px;
}

.appV-actions-panel-rt {
  border-bottom: 1px solid #d1d1d1;
  box-shadow: 0 1px 0px rgba(3, 3, 3, 0.045);
}

.appV-actions-panel-rt .list-group-item.list-view-pf-expand-active {
  background-color: #f5f5f5;
}

.appV-actions-panel.list-group .list-group-item:hover {
  background-color: #fff;
}

.schedule-typeahead-ui .rbt-input {
  font-size: 14px;
  height: 29px;
  border-radius: 3px;
}

.cronTextDeco {
  background: #383434;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 1.25em;
}

.cronTextSubDeco {
  font-size: 1.12em;
  padding: 10px;
  background: #e6e6e6;
}

.setting-nav-sub-menu .caret::before {
  content: '';
  color: #303338;
  display: inline-block;
  margin-left: 20px;
  font-size: 3.62em;
  position: absolute;
}

.setting-nav-sub-menu .vertical-tabs-pf-tab.active .caret::before {
  /* -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg); */
  content: '\F0DA';
  color: #0c86cc;
}

.setting-nav-sub-menu .vertical-tabs-pf-tab.active .caret {
  width: auto;
}

.setting-nav-sub-menu .vertical-tabs-pf-tab.active {
  background: #0487cd;
  color: #fff;
  width: 92%;
  margin-right: 14px;
}

.setting-nav-sub-menu-res-title a {
  font-weight: bold;
  color: #555;
}

.setting-nav-sub-menu-res-title {
  margin-bottom: 12px;
}

.vertical-tabs-pf-tab.active-descendant a {
  font-weight: 100;
}

.vertical-tabs-pf-tab > a {
  padding: 3px 6px 3px 1px;
}

.vertical-tabs-pf-tab.active.active-descendant a {
  color: #fff;
  position: relative;
}

.vertical-tabs-pf-tab.active.active-descendant a .setting-nav-sub-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#vertical-settings-tabs {
  direction: rtl;
  text-align: left;
  overflow: auto;
  height: calc(100vh - 250px);
}

.vertical-tabs-pf-tab.setting-nav-sub-menu-res-title > a:hover,
.vertical-tabs-pf-tab.setting-nav-sub-menu-res-title > a:focus {
  color: #555;
  text-decoration: none;
}

.vertical-tabs-pf-tab.setting-nav-sub-menu > a:hover,
.vertical-tabs-pf-tab.setting-nav-sub-menu > a:focus {
  color: #0088ce;
  text-decoration: none;
}

#ace-editor-code-editor.ace_editor {
  height: inherit !important;
}

/* 
#ace-editor-code-editor.ace_editor .ace_content{
    height:inherit  !important
} */

.vertical-tabs-pf-tab.active > a::before {
  content: '';
}

.alpaca-form legend {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  margin-top: 1.2em;
}

form.alpaca-form div.alpaca-field.alpaca-top {
  height: calc(100vh - 330px);
  overflow: auto;
}

div.form-group.has-error div.css-vj8t7z {
  border-color: #c00;
}

div.css-vj8t7z,
div.css-2o5izw {
  min-height: 25px;
  border-radius: 0px;
}

div.css-vj8t7z .css-1g6gooi,
div.css-2o5izw .css-1g6gooi {
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
}

div.css-vj8t7z .css-1ep9fjw,
div.css-2o5izw .css-1uq0kb5 {
  padding: 3px;
}

div.css-vj8t7z .css-xwjg1b,
div.css-2o5izw .css-xwjg1b {
  margin: 0px;
  margin-right: 1px;
  padding-left: 1px;
}

div.css-vj8t7z .css-12jo7m5,
div.css-2o5izw .css-12jo7m5 {
  padding: 0px;
}

.myJobs-search .form-group {
  width: -webkit-fill-available;
  padding-left: 0px;
}

.myJobs-search .form-group .form-control {
  border-bottom: 1px solid #c6d1d4;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  box-shadow: none;
  font-size: 15px;
}

.myJobs-search .form-group .form-control:hover,
.myJobs-search .form-group .form-control:focus {
  border-color: #7dc3e8;
}

.toolbar-pf .myJobs-search .form-group button[disabled].btn-link span {
  color: #777;
}

.toolbar-pf .myJobs-search .form-group button.btn-link span {
  color: #39a5dc;
}

.alert-info-run-status span {
  margin-top: 5px;
}

.appV-actions-panel-data .alert-info-run-status .pficon.pficon-info {
  display: none;
}

.appV-actions-panel-data .alert-info-run-status {
  padding-left: 20px;
}

.appV-actions-panel-data button {
  margin-top: 15px;
}

.centre-loading div {
  margin-left: auto;
  margin-right: auto;
}

.quickTipsBox {
  list-style: none;
}

.quickTipsBox li {
  font-size: 1.3em;
  font-weight: 600;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  margin: 7px;
  color: #555;
}

.quickTipsBox li span {
  margin-right: 5px;
  font-size: 1.3em;
}

.list-view-pf .list-group-item.noRunsNoHg:hover {
  background: #fff;
}

div.iter-fail-msgs {
  margin-top: 20px;
  word-break: break-word;
  white-space: pre-wrap;
}

.tryFormDiv fieldset,
.tryFormResDiv {
  max-height: calc(100vh - 310px);
  overflow: auto;
}

.form-group.form-group-batch label.control-label {
  display: block;
  margin-bottom: 2px;
}

.form-group.form-group-batch .radio {
  display: initial;
  margin-right: 20px;
}

.annotator-modal {
  width: 100vw;
  margin: 0px;
  padding: 0px !important;
}

.centre-loading-main {
  height: 200px;
}

.annotator-modal .pdf-title {
  position: absolute;
  padding: 5px;
  text-align: center;
  color: #eee;
  margin-left: 20%;
  width: 35%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.annotator-modal .pdf-autosave {
  position: absolute;
  padding: 5px;
  text-align: center;
  color: #ddd;
  margin-left: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.toast-notifications-list-pf {
  top: 110px;
}

.external-toolbar-h .toolbar-pf {
  padding-top: 0px;
  border-bottom: 0px;
}

.external-toolbar-h .toolbar-pf-actions {
  margin-bottom: 0px;
  position: relative;
}

.external-toolbar-h .toolbar-pf-action-right {
  position: absolute;
  padding: 5px;
  text-align: center;
  color: #ddd;
  margin-left: 76%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: -webkit-fill-available;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.external-toolbar-h .bd-title {
  margin-left: 23%;
  position: absolute;
}

.external-toolbar-h .toolbar-pf-action-right.ann-back button {
  color: #90caff;
}

.external-toolbar-h .toolbar-pf-action-right.std-back {
  margin-left: 74%;
}

.buildAppModal .radio input[type='radio'] {
  position: absolute;
}

.buildAppModal .radio label {
  position: relative;
}

.buildAppModal .help-block {
  text-align: left;
  font-size: 12px;
}

.buildAppModal .build-app-embed-name {
  font-size: 15px;
}

.rt-fixed-help-dialog .modal-dialog {
  padding: 0px;
}

.quick-help-img {
  height: 40px;
  margin-right: 5px;
  border: 1.2px solid #a59f9f;
  background-color: #b6bac13b;
}

.rt-fixed-help-dialog button.quick-help-button span,
.rt-fixed-help-dialog a {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-align: left;
}

.rt-fixed-help-dialog button.quick-help-button {
  display: flex;
  width: -webkit-fill-available;
  text-decoration: none;
}

@media (min-width: 768px) {
  .rt-fixed-help-dialog .modal-sm {
    width: 400px;
  }
}

.rt-fixed-help-dialog .modal-header {
  background-color: #f3f8fe;
  padding: 15px 18px;
}

.rt-fixed-help-dialog .modal-header .modal-title {
  font-size: 1.2em;
  color: #555;
}

.rt-fixed-help-dialog .modal-body ul {
  margin-top: 35px;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mb-3 {
  margin-bottom: 3em;
}

.col-center-content {
  margin-left: auto;
  margin-right: auto;
  float: none !important;
}

@media (min-width: 992px) {
  .wizard-pf {
    padding: 60px 0;
    width: 900px;
  }
}

@media (min-width: 768px) {
  .welcome-user-modal .modal-dialog {
    width: 600px;
    margin: 50px auto;
  }
}

.btn-link:hover,
.btn-link:focus {
  outline: none !important;
}

button.rt-fixed-help-dialog-qh-btn {
  text-decoration: none !important;
}

.btn-lg,
.btn-group-lg > .btn,
.btn {
  border-radius: 2px;
}

.modal-lt-header.modal-header {
  background-color: #f3f8fe;
  padding: 15px 18px;
}

.modal-lt-header.modal-header .modal-title {
  font-size: 1.2em;
  color: #555;
}

.pg-viewer-wrapper {
  overflow-y: auto !important;
  height: calc(100vh - 250px) !important;
  min-height: calc(100vh - 250px);
}

.pg-viewer-wrapper .photo-viewer-container,
.pg-viewer-wrapper .photo-viewer-container img {
  width: auto !important;
  height: inherit !important;
}

.react-data-grid-display {
  min-height: auto;
}

.react-data-grid-display .react-grid-Canvas {
  overflow: auto auto !important;
}

.no-preview-container {
  min-height: calc(100vh - 270px);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  flex-direction: column;
}

.sample-data-preview-container {
  min-height: auto;
  overflow: auto;
  height: auto;
}

.downloadDSIMsg li {
  list-style: none;
  display: inline;
}

.center-explore-50 {
  margin: 2em auto;
  width: fit-content;
}

.page-centre-error-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
}

.tab-auto-w a {
  width: auto !important;
}

.preview-centre-loader {
  display: flex;
  justify-content: center;
  height: calc(100vh - 220px);
  align-items: center;
}

.username-tr {
  color: #d1d1d1;
  line-height: 1;
  max-height: 58px;
  padding: 16px 12px;
  /* font-size: 16px; */
  font-size: 14px;
  text-transform: capitalize;
}

.username-tr i {
  font-size: 17px;
}

.user-tr-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

body.cards-pf {
  background: #f5f7fb;
}

.text-orange {
  color: #ff5722;
}

@media (min-width: 1110px) {
  .modal-xl {
    width: 1170px;
  }
}

.raw-setting-status {
  float: right;
  width: 60%;
  margin: 20px 0 0;
}

.raw-setting-container {
  float: left;
  width: 100%;
  margin-bottom: 0px;
}

#myAppsContainerDiv {
  height: calc(100vh - 165px);
  overflow: auto;
}

.move-broom {
  top: -10px;
  position: absolute;
  -webkit-animation: linear infinite alternate;
  -webkit-animation-name: run;
  -webkit-animation-duration: 1s;
}

@-webkit-keyframes run {
  0% {
    left: 0;
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    left: 0;
  }
}

.file-up-element {
  width: 100%;
  float: left;
  margin: 8px auto;
  position: relative;
  display: flex;
  align-items: center;
}

.file-bdy-sec {
  width: 20%;
  padding-right: 10px;
  overflow-wrap: break-word;
  font-family: sans-serif;
  font-weight: 700;
}

.file-progress-sec {
  width: 65%;
  margin-right: 10px;
}

.file-actions-sec {
  display: flex;
  justify-content: space-evenly;
  width: 14%;
}
