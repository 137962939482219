@font-face {
  font-family: 'icomoon';
  src: url('fonts/dt-icons/icomoon.eot?yisl2j');
  src: url('fonts/dt-icons/icomoon.eot?yisl2j#iefix') format('embedded-opentype'),
    url('fonts/dt-icons/icomoon.ttf?yisl2j') format('truetype'),
    url('fonts/dt-icons/icomoon.woff?yisl2j') format('woff'),
    url('fonts/dt-icons/icomoon.svg?yisl2j#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='dt-icon-'],
[class*=' dt-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dt-icon-group:before {
  content: '\e959';
}

.dt-icon-col-select:before {
  content: '\e95c';
}

.dt-icon-redo:before {
  content: '\e957';
}

.dt-icon-undo:before {
  content: '\e958';
}

.dt-icon-table-blank-grid:before {
  content: '\e955';
}

.dt-icon-tables-couple-1:before {
  content: '\e956';
}

.dt-icon-organization:before {
  content: '\e954';
}

.dt-icon-list:before {
  content: '\e95e';
}

.dt-icon-playoff:before {
  content: '\e95f';
}

.dt-icon-increasing-list-order:before {
  content: '\e960';
}

.dt-icon-stopwatch:before {
  content: '\e961';
}

.dt-icon-flow-chart-1:before {
  content: '\e962';
}

.dt-icon-setting:before {
  content: '\e963';
}

.dt-icon-tools:before {
  content: '\e964';
}

.dt-icon-folder:before {
  content: '\e965';
}

.dt-icon-toolbox:before {
  content: '\e953';
}

.dt-icon-globe:before {
  content: '\e951';
}

.dt-icon-ui:before {
  content: '\e952';
}

.dt-icon-flow-chart:before {
  content: '\e950';
}

.dt-icon-custom-templates:before {
  content: '\e94f';
}

.dt-icon-artificial-intelligence:before {
  content: '\e94e';
}

.dt-icon-vision-focus:before {
  content: '\e94d';
}

.dt-icon-strategy:before {
  content: '\e94c';
}

.dt-icon-enterprise:before {
  content: '\e949';
}

.dt-icon-cloud-1:before {
  content: '\e94a';
}

.dt-icon-cloud:before {
  content: '\e94b';
}

.dt-icon-abacus:before {
  content: '\e948';
}

.dt-icon-calendar:before {
  content: '\e947';
}

.dt-icon-SAP-Logo .path1:before {
  content: '\e944';
  color: rgb(17, 17, 17);
}

.dt-icon-SAP-Logo .path2:before {
  content: '\e945';
  margin-left: -2.017578125em;
  color: rgb(255, 255, 255);
}

.dt-icon-SAP-Logo .path3:before {
  content: '\e946';
  margin-left: -2.017578125em;
  color: rgb(17, 17, 17);
}

.dt-icon-studio-1:before {
  content: '\e943';
}

.dt-icon-ml-model-alpha:before {
  content: '\e917';
}

.dt-icon-template-nw:before {
  content: '\e918';
}

.dt-icon-export-block:before {
  content: '\e919';
}

.dt-icon-import-block:before {
  content: '\e91a';
}

.dt-icon-settings-dw:before {
  content: '\e935';
}

.dt-icon-template-blocks:before {
  content: '\e936';
}

.dt-icon-full-screen:before {
  content: '\e937';
}

.dt-icon-dashboard-screen:before {
  content: '\e938';
}

.dt-icon-analysis:before {
  content: '\e939';
}

.dt-icon-settings:before {
  content: '\e93a';
}

.dt-icon-import:before {
  content: '\e93b';
}

.dt-icon-export:before {
  content: '\e93c';
}

.dt-icon-template-filled:before {
  content: '\e93d';
}

.dt-icon-template:before {
  content: '\e93e';
}

.dt-icon-ml-model:before {
  content: '\e93f';
}

.dt-icon-data-block:before {
  content: '\e940';
}

.dt-icon-dstore:before {
  content: '\e941';
}

.dt-icon-io-keys:before {
  content: '\e942';
}

.dt-icon-coding-1:before {
  content: '\e910';
}

.dt-icon-coding-2:before {
  content: '\e911';
}

.dt-icon-analytics:before {
  content: '\e912';
}

.dt-icon-checked:before {
  content: '\e913';
}

.dt-icon-reuse:before {
  content: '\e914';
}

.dt-icon-send:before {
  content: '\e915';
}

.dt-icon-start:before {
  content: '\e916';
}

.dt-icon-network:before {
  content: '\e91b';
}

.dt-icon-networking .path1:before {
  content: '\e91c';
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path2:before {
  content: '\e91d';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path3:before {
  content: '\e91e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path4:before {
  content: '\e91f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path5:before {
  content: '\e920';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path6:before {
  content: '\e921';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path7:before {
  content: '\e922';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path8:before {
  content: '\e923';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path9:before {
  content: '\e924';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path10:before {
  content: '\e925';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path11:before {
  content: '\e926';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path12:before {
  content: '\e927';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path13:before {
  content: '\e928';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.dt-icon-networking .path14:before {
  content: '\e929';
  margin-left: -1em;
  color: rgb(53, 51, 51);
}

.dt-icon-networking .path15:before {
  content: '\e92a';
  margin-left: -1em;
  color: rgb(17, 17, 17);
}

.dt-icon-networking .path16:before {
  content: '\e92b';
  margin-left: -1em;
  color: rgb(17, 17, 17);
}

.dt-icon-networking .path17:before {
  content: '\e92c';
  margin-left: -1em;
  color: rgb(17, 17, 17);
}

.dt-icon-networking .path18:before {
  content: '\e92d';
  margin-left: -1em;
  color: rgb(17, 17, 17);
}

.dt-icon-networking .path19:before {
  content: '\e92e';
  margin-left: -1em;
  color: rgb(17, 17, 17);
}

.dt-icon-networking .path20:before {
  content: '\e92f';
  margin-left: -1em;
  color: rgb(17, 17, 17);
}

.dt-icon-networking .path21:before {
  content: '\e930';
  margin-left: -1em;
  color: rgb(17, 17, 17);
}

.dt-icon-meditation:before {
  content: '\e931';
}

.dt-icon-rocket-ship:before {
  content: '\e932';
}

.dt-icon-idea:before {
  content: '\e933';
}

.dt-icon-drag:before {
  content: '\e934';
}

.dt-icon-analysis:before {
  content: '\e90f';
}

.dt-icon-intelligence:before {
  content: '\e906';
}

.dt-icon-faster-rate:before {
  content: '\e907';
}

.dt-icon-discover-unknown:before {
  content: '\e908';
}

.dt-icon-data-parsing:before {
  content: '\e909';
}

.dt-icon-app-visibility:before {
  content: '\e90a';
}

.dt-icon-eye:before {
  content: '\e90b';
}

.dt-icon-deploy:before {
  content: '\e90c';
}

.dt-icon-data-connect:before {
  content: '\e90d';
}

.dt-icon-assemble:before {
  content: '\e90e';
}

.dt-icon-tie:before {
  content: '\e903';
}

.dt-icon-data-science:before {
  content: '\e904';
}

.dt-icon-puzzle-1:before {
  content: '\e905';
}

.dt-icon-manager:before {
  content: '\e900';
}

.dt-icon-puzzle:before {
  content: '\e901';
}

.dt-icon-developer:before {
  content: '\e902';
}
